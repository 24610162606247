@import '../variables'

.section-header__navigation
  padding: 20px 25px
  min-height: 67px
  +desktop
    padding-bottom: 5px
  +mobile-and-tablet
    padding-bottom: 5px

.section-header__secondary
  position: relative
  +non-mobile
    float: right
    width: $three-columns-wide
  +desktop
    float: right
    width: $three-columns-wide
  +tablet
    display: none
  +mobile
    display: none

.section-header
  margin-bottom: 25px
  background-color: $grey50
  color: $grey700

.section-header__new
  position: relative
  top: -5px
  padding-left: 5px
  vertical-align: middle
  color: red

.section-header__nav-links
  padding:          0
  margin:           0
  font-size:        0

.section-header__nav-link
  margin: 0 6px
  padding: 0
  list-style: none
  display: inline-block
  font-size: 13px

  +mobile-and-tablet
    margin-bottom: 15px
  +desktop
    margin-bottom: 15px

  & > a
    font-size: 13px
    font-weight: bold
    white-space: nowrap
    color: $grey700
    -webkit-font-smoothing: subpixel-antialised
    +mobile
      font-size: 12px
    &:hover
      color: #0f56a3

.section-header__nav-link-image
  max-width: 11px
  max-height: 11px
  margin-right: 5px

.section-header__nav-link-rss-feed
  margin-right: 5px

.section-header__nav-link--dropdown
  background-color: $white
  border-radius: 5px
  min-width: 160px
  vertical-align: top

  +desktop
    min-width: 130px
  +mobile-and-tablet
    min-width: 120px

.section-header
  .dropdown-menu
    // compensate for padding
    margin-left: -$dropdownMenuLinkHorizontalPadding
    margin-right: -$dropdownMenuLinkHorizontalPadding
  .dropdown-menu__items
    top: 30px


.section-header__subscriptions
  display: inline-block
  font-size: 11px
  color: $metaColor

.section-header__subscription-icon
  padding: 0 0 0 5px
  &:before
    font-size: 11px

.section-header__menu
  user-select: none
  position: relative

  .fa-chevron-down
    padding-top: 8px
    font-size: 10px
    float: right
    -webkit-font-smoothing: subpixel-antialised

  &.open-popup
    .section-header__menu-items
      display: block

    i.fa-chevron-down:before
      transform: rotate(180deg)

.section-header__menu-toggle
  color: $linkSecondaryColor
  display: block
  padding: 0 10px
  width: 100%

.section-header__menu-title
  color: $defaultColor
  font-size: 13px
  font-weight: bold
  white-space: nowrap
  -webkit-font-smoothing: subpixel-antialised

.section-header__menu-items
  display: none
  position: absolute
  z-index: 5
  background: $contentBackgroundColor
  padding: 0
  margin: 0
  min-width: $dropdownMenuWidth
  box-shadow: 0 2px 8px rgba(black, 0.3)

.section-header__menu-item,
.section-header__menu-more
  list-style: none
  min-width: $dropdownMenuWidth
  white-space: nowrap

.section-header__menu-link
  font-size: 12px
  font-weight: bold
  position: relative
  display: inline-block
  width: 100%
  background: $contentBackgroundColor
  padding: 3px $dropdownMenuLinkHorizontalPadding
  color: $secondaryTitleColor

  &:hover
    background-color: $secondaryBackgroundColor

.section-header__menu-item--rtl
  direction: rtl
  list-style: none

.section-header__menu-more-link
  font-weight: normal

.section-header__nav-link--secondary
  float: right
  margin: 0 10px
  +mobile-and-tablet
    float: none
