@import '../variables'

.featured-sections
  overflow: hidden
  margin: 0
  padding-top: 8px
  clear: both
  background-color: $grey25
  border: $grey100 solid 1px
  border-radius: 4px
  padding: 20px
  margin-bottom: 50px

.featured-sections__title
  font: 20px/1.2em $bodyFont
  margin-bottom: 10px
  -webkit-font-smoothing: subpixel-antialiased

.featured-sections__section
  overflow: hidden

.featured-sections__section-link
  display: block
  padding: 14px 0
  &:hover, &:active, &:focus
    background: darken($pageBackgroundColor, 2%)

.featured-sections__section-title
  font: bold 17px/1.2em $headingFont
  -webkit-font-smoothing: subpixel-antialiased
  color: $defaultColor
  margin-bottom: 4px

.featured-sections__section-image
  max-width: 30px
  width: 100%
  float: left
  margin-top: 4px

.featured-sections__section-summary
  // Not $font because Jacob.
  font: normal 12px/1.4em $bodyFont
  color: #8a8a8a

.featured-sections__section-title,
.featured-sections__section-summary
  margin-left: 40px

.category-featured-section
  float: left
  width: 100%

  .category-featured-section__header
    font-weight: 600
    font-size: 32px
    line-height: 0px
    color: $newBodyTextColor
    +mobile
      font-size: 24px
