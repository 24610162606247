@import 'variables'
@import 'mixins/responsive'
@import 'mixins/h1'

.layout__content-with-sidebar
  +wide
    width: $nine-columns-wide
    float: left
  +desktop
    width: $six-columns-wide
    float: left
.layout__offset-content-with-sidebar
  position: relative
  +wide
    width: $eight-columns-wide
    float: left
    margin-left: $column-width + $gutter-width
  +desktop
    width: $six-columns-wide
    float: left

.layout__offset-content-with-sidebar--post
  +wide
    width: $seven-columns-wide
    margin-left: $two-columns-wide

.layout__sidebar
  +wide
    width: $three-columns-wide
    float: right
  +desktop
    width: $three-columns-wide
    float: right

.layout__sidebar-primary
  +tablet
    width: $three-columns-wide
    float: left
    margin-right: $gutter-width

.layout__sidebar-secondary
  +tablet
    width: $three-columns-wide
    float: right

.layout__navbar
  +wide
    float: left
    width: $two-columns-wide
    margin-right: $gutter-width
  +desktop
    float: left
    width: $two-columns-wide
    margin-right: $gutter-width

.layout__content-with-navbar
  +wide
    width: $ten-columns-wide
    float: right
  +desktop
    width: $seven-columns-wide
    float: right
