@import '../variables'

.leaderboard_ad_non_mobile
  +mobile
    display: none

.leaderboard_ad_mobile
  +non-mobile
    display: none

.avert
  max-width: $three-columns-wide
  min-height: 250px
  background: url('images/ad-pinstripe.png')
  margin: 0 auto $gutter-width
  &.avert--sticky-footer
    max-width: 728px
    min-height: 90px
    max-height: 90px
    background: none
    .avert__label
      display: none

.avert__wrapper
  overflow: hidden

.avert__content
  text-align: center
  display: block
  margin: 0 auto
  justify-content: center


.avert__label
  display: block
  text-align: center
  font-size: 9.62px
  margin-top: 0px

.avert--leaderboard
  max-width: none
  width: 100%
  border: none
  background: url('images/ad-pinstripe.png')
  min-height: 90px
  padding: 0
  margin-bottom: 0
  .avert__content
    height: 120px
    +tablet
        height: 90px
    +all-desktop
        height: 250px

  .avert__wrapper
    position: relative

  .avert__content
    margin-top:    10px
    margin-bottom: 10px

  .avert__label
    text-align: right
    position: absolute
    right: 0px
    bottom: 0px

.avert--inarticle
  max-width: 336px

.avert--publift
  padding: 10px
  width: 100%
  max-width: 100%
  height: auto

.avert--publift-inset
  display: block
  width: 100%
  height: 250px
  margin: auto

.avert--skyscraper
  .avert__wrapper
    padding-top: 10px

.avert--in-article
  margin-top: $contentSectionMarginBottom
  .avert__content
    max-height: 300px

.avert--sticky-sky-ad
  .avert__content
    width: 160px
    height: 600px

.avert--sidebar
  .avert__content
    width: 300px
    min-height: 300px
    +all-desktop
      max-height: 600px

.avert--hidden
  display: none

.post__fullwidth-inarticle-ad-footer
  .page-footer
    +non-mobile
      padding-bottom: 89px

.post__fullwidth-inarticle-ad-template
  +mobile
    display: none !important

  +all-desktop
    display: flex
    margin-top: 76px
    margin-bottom: 76px
    width: 100vw
    height: 302px
    background: #F3F3F3
    justify-content: center
    margin-left: calc(-1 * (100vw - 850px) / 2)
    .avert
      width: 970px
      margin: 0px
      min-width: 970px
      max-width: 970px
    .avert__wrapper
      overflow: unset
      width: 970px
      height: 250px
      margin-top: 26px
    .avert--in-article .avert__content
      height: 250px
